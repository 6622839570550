<template>
  <div :class="`loading-box item-${type}`">
    <div class="preloader_1">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    // 是否显示头部文字
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.loading-box {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999999999999;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  &.item-1 {
    background: #000;

    .preloader_1 span {
      display: block;
      bottom: 0px;
      width: 9px;
      height: 5px;
      background: #fff;
      position: absolute;
      animation: preloader_1 1.5s infinite ease-in-out;
    }
  }
}
.preloader_1 {
  position: relative;
  width: 50px;
}
.preloader_1 span {
  display: block;
  bottom: 0px;
  width: 9px;
  height: 5px;
  background: #fe3b5d;
  position: absolute;
  animation: preloader_1 1.5s infinite ease-in-out;
}
.preloader_1 span:nth-child(2) {
  left: 11px;
  animation-delay: 0.2s;
}
.preloader_1 span:nth-child(3) {
  left: 22px;
  animation-delay: 0.4s;
}
.preloader_1 span:nth-child(4) {
  left: 33px;
  animation-delay: 0.6s;
}
.preloader_1 span:nth-child(5) {
  left: 44px;
  animation-delay: 0.8s;
}
@keyframes preloader_1 {
  0% {
    height: 5px;
    transform: translateY(0px);
    background: #fe3b5d;
  }
  25% {
    height: 30px;
    transform: translateY(15px);
    background: #ffa247;
  }
  50% {
    height: 5px;
    transform: translateY(0px);
    background: #fe3b5d;
  }
  100% {
    height: 5px;
    transform: translateY(0px);
    background: #fe3b5d;
  }
}
</style>
